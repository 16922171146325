/**
 * 含有定制化处理内容的接入企业
 */
import { getQueryString } from '@/utils';

export const source = getQueryString('source', false) || window.sessionStorage.getItem('_source');
const checkSource = curSource => source && curSource?.toString()?.indexOf(source) > -1;

const checkCompanyCode = curCompanyCode => {
  const companyCode =
    getQueryString('companyCode', false) || window.sessionStorage.getItem('_companyCode');
  return companyCode && curCompanyCode?.indexOf(companyCode) > -1;
};

// 立白
export const isLiby =
  checkSource(1500310101) || checkCompanyCode(process.env.VUE_APP_LIBY_COMPANY_CODE);

// 英硕（demo）
export const isEnsure =
  checkSource(1503010105) || checkCompanyCode(process.env.VUE_APP_ENSURE_COMPANY_CODE);

// 英雄互娱
export const isHero =
  checkSource(1502510105) ||
  checkSource(1504010105) ||
  checkCompanyCode(process.env.VUE_APP_HERO_COMPANY_CODE);

// 字节
export const isByteDance = () =>
  checkSource(1503110105) || checkCompanyCode(process.env.VUE_APP_BYTEDANCE_COMPANY_CODE);

// 快手
export const isAAutoQuicker = () =>
  checkSource(1503410105) || checkCompanyCode(process.env.VUE_APP_AQ_COMPANY_CODE);

// 理想
export const isLiXiang =
  checkSource(1103210102) || checkCompanyCode(process.env.VUE_APP_LIXIANG_COMPANY_CODE);

// 腾讯子公司
export const isTencentC =
  checkSource(220450105) || checkCompanyCode(process.env.VUE_APP_TENCENTC_COMPANY_CODE);

// 商汤
export const isSenseTime = () =>
  checkSource(1501110105) || checkCompanyCode(process.env.VUE_APP_SENSETIME_COMPANY_CODE);

// 科园信海
export const isKyuan =
  checkSource(220520105) || checkCompanyCode(process.env.VUE_APP_KYUAN_COMPANY_CODE);

// 贝壳
export const isBeike = () =>
  checkSource(220460105) || checkCompanyCode(process.env.VUE_APP_BEIKE_COMPANY_CODE);

// 网易
export const isNetease =
  checkSource(1502010105) || checkCompanyCode(process.env.VUE_APP_NETEASE_COMPANY_CODE);

// 协鑫
export const isGcl =
  checkSource(220550105) || checkCompanyCode(process.env.VUE_APP_GCL_COMPANY_CODE);

// 百度
export const isBaidu = () =>
  checkSource(220540105) || checkCompanyCode(process.env.VUE_APP_BAIDU_COMPANY_CODE);

// 亚信
export const isAsiaInfo =
  checkSource(1503910105) || checkCompanyCode(process.env.VUE_APP_ASIAINFO_COMPANY_CODE);

// 中集
export const isCimc =
  checkSource(220590105) || checkCompanyCode(process.env.VUE_APP_CIMC_COMPANY_CODE);

// 德康农牧
export const isDekon =
  checkSource(220490105) || checkCompanyCode(process.env.VUE_APP_DEKON_COMPANY_CODE);

// 特驱农牧
export const isTequ =
  checkSource(220580105) || checkCompanyCode(process.env.VUE_APP_TEQU_COMPANY_CODE);

// 柏楚
export const isBochu =
  checkSource(220610105) || checkCompanyCode(process.env.VUE_APP_BOCHU_COMPANY_CODE);

// 龙湖
export const isLongFor = () =>
  checkSource(1103510101) || checkCompanyCode(process.env.VUE_APP_LONGFOR_COMPANY_CODE);

// BIGO
export const isBigo =
  checkSource(1502610105) || checkCompanyCode(process.env.VUE_APP_BIGO_COMPANY_CODE);

// 乐信
export const isLexin =
  checkSource(1501910105) || checkCompanyCode(process.env.VUE_APP_LEXIN_COMPANY_CODE);

// 阿里互娱
export const isAliGame =
  checkSource(1503710105) || checkCompanyCode(process.env.VUE_APP_ALI_GAME_COMPANY_CODE);

// 稳健
export const isWenjian =
  checkSource(220650105) || checkCompanyCode(process.env.VUE_APP_WENJIAN_COMPANY_CODE);

// 地平线
export const isDipingxian =
  checkSource(1104110105) || checkCompanyCode(process.env.VUE_APP_DIPINGXIAN_COMPANY_CODE);

// 红西瓜
export const isHongxigua =
  checkSource(220670105) || checkCompanyCode(process.env.VUE_APP_HONGXIGUA_COMPANY_CODE);

// 港中大
export const isGangzhongda = () =>
  checkSource(220640105) || checkCompanyCode(process.env.VUE_APP_GANGZHONGDA_COMPANY_CODE);

// 作业帮
export const isZuoyebang = () =>
  checkSource(220730105) || checkCompanyCode(process.env.VUE_APP_ZUOYEBANG_COMPANY_CODE);

// 慧智微
export const isSmarterMicro = () =>
  checkSource(220760105) || checkCompanyCode(process.env.VUE_APP_SMARTER_MICRO_COMPANY_CODE);

// SOHO
export const isSOHO =
  checkSource(220840105) || checkCompanyCode(process.env.VUE_APP_SOHO_COMPANY_CODE);

// 优奈柯恩
export const isNreal =
  checkSource(1503610105) || checkCompanyCode(process.env.VUE_APP_NREAL_COMPANY_CODE);

// 精电
export const isJingDian = () =>
  checkSource(220790105) || checkCompanyCode(process.env.VUE_APP_JINGDIAN_COMPANY_CODE);

// 友宝
export const isYouBao = () =>
  checkSource(1502410105) || checkCompanyCode(process.env.VUE_APP_YOUBAO_COMPANY_CODE);

// TCL
export const isTcl = () =>
  checkSource(1503210105) || checkCompanyCode(process.env.VUE_APP_TCL_COMPANY_CODE);

// 深圳惠泰医疗器械
export const isSZHT = () => checkCompanyCode(process.env.VUE_APP_SZHT_COMPANY_CODE);

// 长鑫
export const isChangXin = () =>
  checkSource(220710105) || checkCompanyCode(process.env.VUE_APP_CHANGXIN_COMPANY_CODE);

// 追觅
export const isDreame = () =>
  checkSource(220850105) || checkCompanyCode(process.env.VUE_APP_DREAME_COMPANY_CODE);

// 科电贸易
export const isEse = () => checkCompanyCode(process.env.VUE_APP_ESE_COMPANY_CODE);

// 烟台工会
export const isYanTai = () =>
  checkSource(220880105) || checkCompanyCode(process.env.VUE_APP_YANTAI_COMPANY_CODE);

// 宁德时代
export const isCatl = () =>
  checkSource(220890105) || checkCompanyCode(process.env.VUE_APP_CATL_COMPANY_CODE);

// 红松在线
export const isHongSong = () =>
  checkSource(220930105) || checkCompanyCode(process.env.VUE_APP_HONGSONG_COMPANY_CODE);

// 李锦记
export const isLkk = () =>
  checkSource(1501210105) || checkCompanyCode(process.env.VUE_APP_LKK_COMPANY_CODE);

// 成都运达科技股份有限公司
export const isYunDa = () => checkCompanyCode(process.env.VUE_APP_YUNDA_COMPANY_CODE);

// 四川特驱投资集团有限公司
export const isTequSichuan = () => checkCompanyCode(process.env.VUE_APP_TEQUSC_COMPANY_CODE);

// 英皇
export const isEmperor = () =>
  checkCompanyCode(process.env.VUE_APP_EMPEROR_ONE_COMPANY_CODE) ||
  checkCompanyCode(process.env.VUE_APP_EMPEROR_TWO_COMPANY_CODE);

// 咖世家
export const isCosta = () => checkCompanyCode(process.env.VUE_APP_COSTA_COMPANY_CODE);

// 访问首页无需token的企业
export const sourceNoLogin = isTencentC || isSenseTime() || isLiby;
