import {
  isChangXin,
  isEse,
  isHongSong,
  isJingDian,
  isLkk,
  isSZHT,
  isSmarterMicro,
  isTcl,
  isYouBao,
  isYunDa,
  isTequSichuan,
  isEmperor,
  isCosta,
} from '@/constants/source';
import Apis from '@/api';
// import { Dialog } from 'vant';
import { toWechatMiniprogram, cryptoDecrypt } from '@/utils/utils';
import thirdpartyResolve from '@/mixins/thirdParty';
import { isMobile } from '@/utils';

const toInsuranceCompany = async supplierEncode => {
  if (supplierEncode === 'PAH') {
    // 平安
    const params = { serviceCode: 'claim' };
    const res = await Apis.getPingAnClaimLink(params);
    if (res?.code === '0' && res?.success) {
      const url = res?.data?.serviceUrl || '';
      window.location.href = url;
    }
  } else if (supplierEncode === 'FX') {
    // 复兴
    const res = await Apis.getFosunLink();
    if (res?.code === '0' && res?.success) {
      const url = res?.data?.url || '';
      window.location.href = url;
    }
  }
};

export default async next => {
  try {
    if (isCosta()) {
      // 咖世家
      thirdpartyResolve({ redirectType: 53 }, true);
      return;
    }

    if (isEmperor()) {
      // 英皇
      window.location.replace('https://ihealth.zhongan.com/wxmp/pages/claim-list');
      return;
    }

    // 企业定制理赔处理
    if (isLkk()) {
      // 李锦记
      if (isMobile()) {
        window.location.href = 'https://wx.helianwap.com/mobile/enterprise-space.html';
      } else {
        window.location.href = 'https://admin.helianhealth.com/enterprisepc/#/login?redirect=/home';
      }
      return;
    }

    if (isHongSong()) {
      // 红松
      thirdpartyResolve({ redirectType: 55 }, true);
      return;
    }

    if (isJingDian()) {
      // 精电
      next({ name: 'jdClaim' });
      return;
    }

    if (isTcl() || isSZHT()) {
      // TCL、深圳惠泰医疗器械 跳转平安理赔小程序
      toWechatMiniprogram();
      return;
    }

    if (isYunDa()) {
      window.location.replace(
        `${window.location.origin}/spcare-products/health-fw-static/cms?pageCode=P000050`,
      );
      return;
    }

    if (isTequSichuan()) {
      window.location.replace(
        'https://ulink.home.pingan.com.cn/redirect.html?deepLinkType=TZYWFWBC',
      );
      return;
    }

    if (isYouBao()) {
      // 友宝根据员工类型跳转
      const mRes = await Apis.getMemberVisibleClassify({
        comId: window.sessionStorage.getItem('_companyCode') || '',
      });
      if (mRes?.code === '0' && mRes?.data) {
        const comVisibleClassifyName = mRes.data?.comVisibleClassifyName || '';
        const comVisibleClassifyId = mRes.data?.comVisibleClassifyId || '';
        if (comVisibleClassifyName === '高管') {
          // 友宝高管跳转平安理赔小程序
          const loginInfo = JSON.parse(window.localStorage.getItem('loginMsg'));
          toWechatMiniprogram(
            {
              source: '1502410105',
              path: '/pages/index/index',
              query: '',
            },
            {
              platformId: 2,
              phone: cryptoDecrypt(loginInfo?.phone) || '',
              userId: loginInfo?.userId || '',
              publickey: '0F7C0EA8D39768544553789D06EFD121',
            },
          );
        } else {
          const cRes = await Apis.getYouBaoCommonClaimLink({
            refField1: comVisibleClassifyId,
            urlType: 'mb',
            companyEncode: window.sessionStorage.getItem('_companyCode') || '',
          });
          if (cRes?.code === '0' && cRes?.data && cRes?.data?.url) {
            window.location.href = cRes.data.url;
          } else {
            next({ name: 'spClaim', query: { isReady: 0 } });
          }
        }
      }
      return;
    }

    if (isChangXin()) {
      // 长鑫跳转保险落地页
      next({ name: 'insurance' });
      return;
    }

    if (isEse()) {
      // 科电贸易
      window.location.href = `${window.location.origin}/spcare-products/health-fw-static/cms?pageCode=P000005`;
      return;
    }

    if (isSmarterMicro()) {
      // 慧智微
      window.location.replace('https://mes.citic-prudential.com.cn/imobile');
      return;
    }

    const res = await Apis.getInsuranceCompany({
      companyEncode: window.sessionStorage.getItem('_companyCode') || '',
    });
    if (res?.code === '0' && res?.success && res?.data?.supplierEncode) {
      const { supplierEncode, tipMsg } = res?.data || {};
      const key = '_insurance_company';
      const value = window.localStorage.getItem(key);
      if (tipMsg && supplierEncode && !value) {
        // Dialog.alert({
        //   title: '温馨提示',
        //   message: tipMsg,
        //   messageAlign: 'left',
        //   theme: 'round-button',
        //   showConfirmButton: true,
        //   confirmButtonText: '继续',
        // }).then(async () => {
        window.localStorage.setItem(key, 1);
        await toInsuranceCompany(supplierEncode);
        // });
      } else {
        await toInsuranceCompany(supplierEncode);
      }
    } else {
      next({ name: 'spClaim', query: { isReady: 1 } });
    }
  } catch (error) {
    console.log(error);
    next({ name: 'spClaim', query: { isReady: 1 } });
  }
};
