import elmentEnLocale from 'element-ui/lib/locale/lang/en';
export default {
  // 企业通用
  GLOBAL_000: 'Loading',
  GLOBAL_001: 'Platform Service Agreement',
  GLOBAL_002: 'Privacy Policy',
  GLOBAL_003: 'Kind Reminder',
  GLOBAL_004:
    'You are redirecting to the Dingdang Kuaiyao page soon.The Medicine Service is provide by Dingdang Kuaiyao, if you have any questions,please contact the customer service of Dingdang Kuaiyao.',
  GLOBAL_005: 'Ok',
  GLOBAL_006: 'Employee',
  GLOBAL_007: 'Spouse',
  GLOBAL_008: 'Child',
  GLOBAL_009: 'Parent',
  GLOBAL_010: 'Father',
  GLOBAL_011: 'Mother',
  GLOBAL_012: 'Parent-in-Law',
  GLOBAL_013: 'Spouse Father',
  GLOBAL_014: 'Spouse Mother',
  GLOBAL_015: 'Others',
  GLOBAL_016: 'CNY',
  GLOBAL_017: 'Details',
  GLOBAL_018: 'Balance',
  GLOBAL_019: 'Kind Reminder',
  GLOBAL_020: 'Confirm',
  GLOBAL_021: 'Renewal',
  GLOBAL_022: 'All',
  GLOBAL_023: 'Unpaid',
  GLOBAL_024: 'Underwriting',
  GLOBAL_025: 'Completed',
  GLOBAL_026: 'Cancelled',
  GLOBAL_027: 'Refunding',
  GLOBAL_028: 'Refunded',
  GLOBAL_029: 'Insurant',
  GLOBAL_029_P: 'Insured Person',
  GLOBAL_029_S: 'Insured',
  GLOBAL_030: 'Coverage Amount',
  GLOBAL_031: 'Deductible',
  GLOBAL_032: 'Reimbursement Ratio',
  GLOBAL_033: 'Cancelled',
  GLOBAL_034: 'Redirecting to the payment page now. Please wait.',
  GLOBAL_035: 'The Flex enrollment period is overlapped.',
  GLOBAL_036: 'The annual enrollment is not open',
  GLOBAL_037: 'The enrollment is not open',
  GLOBAL_038: 'The monthly enrollment is not open',
  GLOBAL_039: 'The enrollment is not open',
  GLOBAL_040: 'You are not in the enrollment',
  GLOBAL_041: 'Kind Reminder',
  GLOBAL_042:
    'Life events refer to the addition of coverage for newly married spouses or newborn children after the annual/monthly enrollment period. You have not currently activated the life event selection. For detailed policy information, please refer to the welfare manual.',
  GLOBAL_043: 'Confirm',
  GLOBAL_044: 'This function is not yet available, please stay tuned!',
  GLOBAL_045: 'Kind Reminder',
  GLOBAL_046: '该商品您已经提交过订单，若需修改，请前往我的保单进行取消。',
  GLOBAL_047: 'View Order',
  GLOBAL_048: 'Kind Reminder',
  GLOBAL_049: '该商品您已经提交过订单，若需修改，请前往我的保单进行取消。',
  GLOBAL_050: 'View Order',
  GLOBAL_051: 'Kind Reminder',
  GLOBAL_052:
    'The insurant {name} does not meet the age requirements for this product. Please change another one for insurance coverage',
  GLOBAL_053: 'Confirm',
  GLOBAL_054: 'Order is cancelled',
  GLOBAL_055: 'Reason for order cancellation:',
  GLOBAL_056: 'I filled in the incorrect information.',
  GLOBAL_057: 'I want to modify the insured.',
  GLOBAL_058: "I don't need it.",
  GLOBAL_059: 'Other reason [please fill in]',
  GLOBAL_061: 'Please fill in the reason here',
  GLOBAL_062: 'Submit',
  GLOBAL_063: 'Please select a cancellation reason',
  GLOBAL_064: 'Please fill in the reason for cancellation',
  GLOBAL_065: 'Please select',
  GLOBAL_066: 'Please enter',
  GLOBAL_067: 'Kind Reminder',
  GLOBAL_060: 'Confirm',
  GLOBAL_111: 'No',
  GLOBAL_068: 'National ID',
  GLOBAL_069: 'Passport',
  GLOBAL_070: 'Birth Certificate',
  GLOBAL_071: 'Military Officer Certificate',
  GLOBAL_072: 'Household Registration Booklet',
  GLOBAL_073: 'Mainland Travel Permit for Hong Kong and Macao Residents',
  GLOBAL_074: 'Mainland Travel Permit for Taiwan Residents',
  GLOBAL_075: "Foreigner's Permanent Residence Permit",
  GLOBAL_076: 'Mainland Residence Permit for Hong Kong and Macao Residents',
  GLOBAL_077: 'Mainland Residence Permit for Taiwan Residents',
  GLOBAL_078: 'Others',
  GLOBAL_079: 'Male',
  GLOBAL_080: 'Female',
  GLOBAL_081: 'Others',
  GLOBAL_082: 'Credits',
  GLOBAL_083: 'PayrollDeduction',
  GLOBAL_084: 'WeChatPay',
  GLOBAL_085: 'AliPay',
  GLOBAL_086: 'MultiplePayment',
  GLOBAL_087: 'Credits、PayrollDeduction MultiplePayment',
  GLOBAL_088: 'Offline Payment',
  GLOBAL_089: 'China',
  GLOBAL_090: 'Hong Kong, China',
  GLOBAL_091: 'Macao, China',
  GLOBAL_092: 'Taiwan, China',
  GLOBAL_093: 'America',
  GLOBAL_094: 'Canada',
  GLOBAL_095: 'United Kingdom',
  GLOBAL_096: 'Malaysia',
  GLOBAL_097: 'Australia',
  GLOBAL_098: 'Japan',
  GLOBAL_100: 'Payment Amount',
  GLOBAL_101: 'Total Amount:',
  GLOBAL_102: 'Discount Amount:',
  GLOBAL_103: ' (Discount Rate: {num}%)',
  GLOBAL_104: 'Amount:',
  GLOBAL_105: 'Review details',
  GLOBAL_106: 'Amount details',
  GLOBAL_107: 'The final deduction amount shall be subject to the actual order',
  GLOBAL_108: 'Reminder',
  GLOBAL_109: 'Back to Modify',
  GLOBAL_110: 'times of the monthly salary',
  GLOBAL_200: 'Network abnormality, please try refreshing!',
  GLOBAL_300: 'Nationality',
  LOGIN_000: 'Sign In',
  LOGIN_001: 'Phone Number',
  LOGIN_002: 'Enter your phone number',
  LOGIN_003: 'Please enter.',
  LOGIN_004: 'Please enter the correct mobile phone number.',
  LOGIN_005: 'Code',
  LOGIN_006: 'Enter verification code',
  LOGIN_007: 'Send',
  LOGIN_008: 'Resend after {count}s',
  LOGIN_009: 'Agree to the terms of {a} and {b} to continue.',
  LOGIN_010:
    'Please agree to the terms of Platform Service Agreement and Privacy Policy to continue.',
  LOGIN_011: 'Please fill in the information completely.',
  LOGIN_012: 'Sign In',
  AUTH_001: 'Name',
  AUTH_002: 'Please enter',
  AUTH_003: 'Please enter the correct name',
  AUTH_004: 'Sign Out',
  AUTH_005: 'Submit',
  AUTH_006: 'Please fill in the information completely.',
  COMPLETE_000: 'Please fill in your personal information before you start to enroll',
  COMPLETE_001: 'Name of Employee',
  COMPLETE_002: 'Please enter',
  COMPLETE_003: 'ID Type',
  COMPLETE_004: 'Please select',
  COMPLETE_005: 'ID Number',
  COMPLETE_006: 'Please enter',
  COMPLETE_007: 'Gender',
  COMPLETE_008: 'Please select',
  COMPLETE_009: 'Date of Birth',
  COMPLETE_010: 'Please select',
  COMPLETE_011: 'Sign Out',
  COMPLETE_012: 'Submit',
  COMPLETE_013: 'Confirm',
  COMPLETE_014: 'Please confirm that you have filled in all the information completely',
  COMPLETE_015: 'Please enter the correct name',
  COMPLETE_016: 'Please enter the correct name',
  COMPLETE_017: 'Please enter the correct ID number',
  COMPLETE_018: 'Reminder',
  COMPLETE_019: 'Back to Modify',
  COMPLETE_020: 'Confirm',
  COMPLETE_021:
    "The person is older than 65 years old. Please confirm whether it is the employee's information.",
  COMPLETE_022:
    "The person is under 18 years old. Please confirm whether it is the employee's information.",
  COMPLETE_023: 'Please select',
  EMAIL_001: 'Company Email Verification',
  EMAIL_002: 'Send',
  EMAIL_003: 'Resend',
  EMAIL_004: 'Resend after {count}s',
  EMAIL_005: 'Sign Out',
  EMAIL_006: 'Submit',
  EMAIL_007: 'Please select email suffix',
  EMAIL_008: 'Confirm',
  EMAIL_009: 'Enter email prefix',
  EMAIL_010: 'Enter verification code',
  EMAIL_011: 'Please enter the email prefix.',
  EMAIL_012: 'Maximum 32 characters',
  EMAIL_013: 'Only letters, numbers, dots, minus signs or undearscores are allowed',
  EMAIL_014: 'Please fill in the information completely.',
  PCFORBID_001: 'Please access via the mobile terminal',
  UPOPEN_001: 'Your company’s Benefits Mall is not yet available.',
  UPOPEN_002: 'Your company’s Benefits Pie is not yet available.',
  USERCENTER_000: 'Me',
  USERCENTER_001: 'Phone Number',
  USERCENTER_002: 'ID Number',
  USERCENTER_003: 'Sign Out',
  USERCENTER_004: 'Please enter your new mobile phone number',
  USERCENTER_005:
    'The mobile phone number currently bound to this account is {Phone}. Please enter your new mobile phone number.',
  USERCENTER_006: 'New phone number',
  USERCENTER_007: 'SMS verification code has been sent to {Phone},  please check.',
  USERCENTER_008: 'Enter your phone number',
  USERCENTER_009: 'Enter your phone number.',
  USERCENTER_010: 'Please enter the correct mobile phone number',
  USERCENTER_011: 'Enter verification code',
  USERCENTER_012: 'Please enter a text verification code',
  USERCENTER_013: 'Send Verification Code',
  USERCENTER_014: 'Verification code has been sent to {Phone}',
  USERCENTER_015: 's Resend',
  USERCENTER_016: 'Resend',
  USERCENTER_017: 'Submit',
  USERCENTER_018: 'The phone number is same as the current one',
  USERCENTER_019: 'Updated',
  ABOUTUS_000: 'About Us',
  ABOUTUS_001: 'Company Profile',
  FAMILYLIST_001: 'Manage Family Members',
  FAMILYLIST_002: 'year(s) old',
  FAMILYLIST_003: 'Add new family members',
  FAMILYLIST_004: 'Add new family members',
  FAMILYDETAIL_001: 'Family Members Information',
  FAMILYDETAIL_002: 'Personal Information',
  FAMILYDETAIL_003: 'Name',
  FAMILYDETAIL_004: 'Please enter',
  FAMILYDETAIL_005: 'Please enter your name',
  FAMILYDETAIL_006: 'Please enter the correct name',
  FAMILYDETAIL_007: 'ID Type',
  FAMILYDETAIL_008: 'Please select',
  FAMILYDETAIL_009: 'Please select a ID type',
  FAMILYDETAIL_010: 'ID Number',
  FAMILYDETAIL_011: 'Please enter',
  FAMILYDETAIL_012: 'Please enter your ID number',
  FAMILYDETAIL_013: 'Please enter the correct ID number',
  FAMILYDETAIL_014: 'Relationship with Employee',
  FAMILYDETAIL_015: 'Please select',
  FAMILYDETAIL_016: 'Please select the relation to Individual',
  FAMILYDETAIL_017: 'Gender',
  FAMILYDETAIL_018: 'Please select',
  FAMILYDETAIL_019: 'Please select a gender',
  FAMILYDETAIL_020: 'Date of Birth',
  FAMILYDETAIL_021: 'Please select',
  FAMILYDETAIL_022: 'Please select the date of birth',
  FAMILYDETAIL_023: 'Date of Marriage',
  FAMILYDETAIL_024: 'Please select (optional)',
  FAMILYDETAIL_025: 'Phone Number of Contact',
  FAMILYDETAIL_026: 'Please enter (optional)',
  FAMILYDETAIL_027: 'Please select',
  FAMILYDETAIL_028: 'Please enter',
  FAMILYDETAIL_029: 'Please enter the correct mobile phone number',
  FAMILYDETAIL_030: 'Delete Family Member',
  FAMILYDETAIL_031: 'Confirm',
  FAMILYDETAIL_032: 'Confirm',
  FAMILYDETAIL_033: 'Delete',
  FAMILYDETAIL_034:
    "Deleting the family member will clear all their unsubmitted selection.Completed selection that have already been paid will not be affected, Please confirm if you want to delete this family member's information.",
  FAMILYDETAIL_035: 'Delete',
  FAMILYDETAIL_036: 'Cancel',
  FAMILYDETAIL_037: 'Family member deleted successfully. Returning to the list page now.',
  FAMILYDETAIL_038: 'Continue editing',
  FAMILYDETAIL_039: 'Cancel',
  FAMILYDETAIL_040: 'Additon successful',
  FAMILYDETAIL_041: 'Updated',
  FAMILYDETAIL_042: 'Add new family members',
  FAMILYDETAIL_043: 'Edit family member',
  FAMILYDETAIL_044: 'Please select nationality！',
  FAMILYDETAIL_045:
    "The employee's personal information cannot be modified by yourself. If you need to modify, please contact the customer service or HR On Call.",
  HOMEPAGE_000: 'Home',
  HOMEPAGE_001: 'The Flex enrollment period is overlapped.',
  HOMEPAGE_002: 'Kind Reminder',
  HOMEPAGE_003: 'Never pop-up next time',
  CHOOSE_001: 'Confirm Selection',
  CHOOSE_002: 'Reminder',
  CHOOSE_003: 'Please modify the insurant information provided below',
  CHOOSE_004: 'The insurance information is inconsistent with the family member information',
  CHOOSE_005: 'Back to Modify',
  CHOOSE_006: 'Please Select Product',
  CHOOSE_007: 'Reminder',
  CHOOSE_008: 'You have incomplete health information',
  CHOOSE_008_P: 'You have incomplete insured information',
  CHOOSE_009: 'click confirm to enter {page}',
  CHOOSE_010: 'Product Details Page',
  CHOOSE_011: 'Confirm',
  CHOOSE_012: 'View Benefit',
  CHOOSE_013: 'Complete the payment',
  CHOOSE_014: 'Reselect',
  CHOOSE_015:
    'The status of the annual plan order has changed and is currently not available for modification. Please contact the platform or HR for assistance.',
  CHOOSE_016: 'Review the Plan',
  CHOOSE_017: 'Change Option',
  CHOOSE_018: 'Select',
  CHOOSE_019: 'Yes',
  CHOOSE_020: 'No',
  CHOOSE_021:
    'You have unsubmitted benefit choices. To ensure your benefits take effect as planned, please complete the payment at your early convenience.',
  CHOOSE_022:
    'You have submitted your benefit choices.  You can review your selections or reselect them.',
  CHOOSE_023: 'This action will cancel your selected order immediately. Are you sure to proceed?',
  CHOOSE_024:
    'This action will cancel your order and refund your payment immediately, you need to confirm your selection and complete the payment again, otherwise your selection will not take effect. Are you sure to proceed?',
  CHOOSE_025: 'View',
  CHOOSE_026: 'Notice',
  CHOOSE_027: '',
  CHOOSE_028: '',
  PRODUCTDETAIL_000: 'Please review for more details',
  PRODUCTDETAIL_001: 'Confirm',
  PRODUCTDETAIL_002: 'Submit the order ({num}) person',
  PRODUCTDETAIL_003: 'Reminder',
  PRODUCTDETAIL_004: 'The following insurants are not eligible for the purchase restriction rules',
  PRODUCTDETAIL_005: 'Continued coverage is available',
  PRODUCTDETAIL_006: 'Insurance is available',
  PRODUCTDETAIL_007: 'Back to Modify',
  PRODUCTDETAIL_008: 'Enroll yourself',
  PRODUCTDETAIL_009: 'Enroll your dependents',
  PRODUCTDETAIL_010: 'Enroll yourself and your dependents',
  PRODUCTDETAIL_011: 'Remove',
  PRODUCTDETAIL_012: 'Health declaration completed. Click to edit.',
  PRODUCTDETAIL_013: 'Please complete health declaration',
  PRODUCTDETAIL_014: 'View',
  PRODUCTDETAIL_015: 'Modify',
  PRODUCTDETAIL_016: 'Enroll Now',
  PRODUCTDETAIL_017: 'Enroll Now',
  PRODUCTDETAIL_018: 'Enroll Now',
  PRODUCTDETAIL_019: 'Kind Reminder',
  PRODUCTDETAIL_020: '，不符合本产品的参保年龄范围。',
  PRODUCTDETAIL_021: 'Confirm',
  PRODUCTDETAIL_022: 'Confirm',
  PRODUCTDETAIL_023:
    "If you need to add coverage, please click on 'Add Family Information' to complete the subsequent steps. If you do not need to add coverage, please click on the top-left corner of the page to return and exit the page.",
  PRODUCTDETAIL_024: 'Relationship',
  PRODUCTDETAIL_030: 'View Order',
  PRODUCTDETAIL_031: 'Age does not comply with the purchase restriction group rules',
  PRODUCTDETAIL_031_0: 'People aged over 80 are not eligible for enrollment.', // 续保
  PRODUCTDETAIL_031_1: 'People aged over 75 are not eligible for enrollment.', // 新保
  PRODUCTDETAIL_031_2: 'Children aged over 23 are not eligible for enrollment.',
  PRODUCTDETAIL_031_3: "​Children can't be older than the employee. Please verify.",
  PRODUCTDETAIL_031_4: "Parents can't be younger than the employee. Please verify.",
  PRODUCTDETAIL_031_5: "Parent-in-law can't be younger than the spouse, please verify.",
  PRODUCTDETAIL_031_6: 'Spouse aged under 20 is not eligible for enrollment.',
  PRODUCTDETAIL_031_7: 'Employee aged under 16 is not eligible for enrollment.',
  PRODUCTDETAIL_102: 'Selected: ',
  PRODUCTDETAIL_103: 'Redeemed ',
  PRODUCTDETAIL_032: 'Please Select an Option',
  PRODUCTDETAIL_033: 'Details',
  PRODUCTDETAIL_034: 'Insurance Portfolio',
  PRODUCTDETAIL_035: 'Please select a plan',
  PRODUCTDETAIL_036: 'The same group of people can only purchase the same solution or plan',
  PRODUCTDETAIL_037: 'Coverage Details',
  PRODUCTDETAIL_038: 'Confirm',
  PRODUCTDETAIL_039: 'insurant',
  PRODUCTDETAIL_040: 'dependent(s)',
  PRODUCTDETAIL_043: 'Add another insurant',
  PRODUCTDETAIL_044: 'Next',
  PRODUCTDETAIL_045: 'Lack of primary insured!',
  PRODUCTDETAIL_046: 'Insufficient Number of Dependents!',
  PRODUCTDETAIL_047: '当前购买人不符合限购规则！',
  PRODUCTDETAIL_048: 'Please select a plan first',
  PRODUCTDETAIL_049: 'Add',
  PRODUCTDETAIL_050: "the insured's",
  PRODUCTDETAIL_051: 'Please enter',
  PRODUCTDETAIL_052: 'ID Type',
  PRODUCTDETAIL_053: 'Please select',
  PRODUCTDETAIL_054: 'ID Number',
  PRODUCTDETAIL_055: 'Please enter',
  PRODUCTDETAIL_056: 'Date of Birth',
  PRODUCTDETAIL_057: 'Please select',
  PRODUCTDETAIL_058: 'Gender',
  PRODUCTDETAIL_059: 'Please select',
  PRODUCTDETAIL_060: 'Date of Marriage',
  PRODUCTDETAIL_061: 'Please select',
  PRODUCTDETAIL_062: 'Confirm',
  PRODUCTDETAIL_063: 'Confirm',
  PRODUCTDETAIL_064: 'Current member has been added, please avoid duplicate enrollment!',
  PRODUCTDETAIL_065: 'Please Select Dependent Type!',
  PRODUCTDETAIL_066: 'Please enter {prefix} name.',
  PRODUCTDETAIL_067: '{prefix} name typo.',
  PRODUCTDETAIL_068: 'Please select {prefix} ID type.',
  PRODUCTDETAIL_069: 'Please enter {prefix} {text} number.',
  PRODUCTDETAIL_070: 'National ID number typo.',
  PRODUCTDETAIL_071: '{prefix} {text} typo.',
  PRODUCTDETAIL_072: 'Please select {prefix} date of birth.',
  PRODUCTDETAIL_073: 'Please select {prefix} gender.',
  PRODUCTDETAIL_074: 'Please select a wedding date.',
  PRODUCTDETAIL_075: 'The phone number entered is incorrect, please re-enter.',
  PRODUCTDETAIL_100: 'Coverage Details',
  PRODUCTDETAIL_101: 'Confirm',
  PRODUCTDETAIL_150: 'Review All Plans',
  PRODUCTDETAIL_151: 'Reselect',
  PRODUCTDETAIL_152: 'Back',
  PRODUCTDETAIL_153: 'Paid Plan',
  PRODUCTDETAIL_154: 'Selected Plan',
  PRODUCTDETAIL_155: 'Not eligible for insurant relationship',
  PRODUCTDETAIL_160:
    'The current purchasing member does not comply with the purchase restriction rules!',
  HEALTHDECLARE_001: 'Health Declaration',
  HEALTHDECLARE_002: 'Progress of Health Declaration ({num} in total)',
  HEALTHDECLARE_004: 'ID Number',
  HEALTHDECLARE_005: 'Confirm',
  HEALTHDECLARE_006: 'Submit',
  HEALTHDECLARE_007: 'Some issues occured',
  HEALTHDECLARE_008: 'No issues occured, confirm',
  HEALTHDECLARE_009: 'Health Declaration',
  HEALTHDECLARE_010: 'Please type your health information below:',
  HEALTHDECLARE_011: 'Submit',
  HEALTHDECLARE_012: 'Reminder',
  HEALTHDECLARE_013: '对不起，您目前的健康状况不符合产品承保要求，请查看其他产品。',
  HEALTHDECLARE_014: 'Return',
  HEALTHDECLARE_015: 'Please enter your questions',
  HEALTHDECLARE_020: 'Please fill in ',
  HEALTHDECLARE_021: 'Image upload cannot exceed 10MB',
  HEALTHDECLARE_022: 'Please upload the PNG JPG format image',
  HEALTHDECLARE_023: 'uploading...',
  HEALTHDECLARE_024: 'upload failed',
  HEALTHDECLARE_025: 'Image upload in progress, please try again later',
  HEALTHDECLARE_026: 'Please confirm above issues for the insured person [{insured}].',
  CONFIRM_000: 'Submit',
  CONFIRM_001: 'Reselect',
  CONFIRM_002: 'Kind Reminder',
  CONFIRM_003: 'Submit',
  CONFIRM_004: 'Back to Modify',
  CONFIRM_005: 'Please read and agree to the terms.',
  CONFIRM_006: 'Kind Reminder',
  CONFIRM_007: '年度计划已删除',
  CONFIRM_008: 'Confirm',
  CONFIRM_009: 'Review the Plan',
  CONFIRM_010: 'Details of insurance liability',
  CONFIRM_011:
    'Do you need a call from the insurer to remind you of renewal before the insurance expires?',
  CONFIRM_012:
    'Please confirm if the insurance company is allowed to provide a telegraphic reminder before the renewal period expires',
  CONFIRM_013: 'Yes',
  CONFIRM_014: 'No',
  CONFIRM_015_O: 'I have read and agree',
  CONFIRM_015: 'I have read and agree（{num}/{total}）',
  CONFIRM_016: 'go on reading the next',
  SETTLE_001: 'Settlement Confirmation',
  SETTLE_002: 'Amount',
  SETTLE_003: 'Payment Amount',
  SETTLE_004: 'Total Amount:',
  SETTLE_005: 'Amount:',
  SETTLE_006: 'Pay',
  SETTLE_007: "You haven't make any selections",
  SETTLE_008: 'go and select the plan you want.',
  SETTLE_009: 'Are you sure to delete?',
  SETTLE_010: 'Kind Reminder',
  SETTLE_011: 'Back to Modify',
  PAYRESULT_000: 'Payment Result',
  PAYRESULT_001: 'You already have completed this selection!',
  PAYRESULT_002: 'Payment Successful',
  PAYRESULT_003: 'Pay channel error, please contact us',
  PAYRESULT_004: 'Getting payment result',
  PAYRESULT_005: 'Product Name',
  PAYRESULT_006: 'Insurant',
  PAYRESULT_007: 'Order Amount',
  PAYRESULT_008: 'Payment Method',
  PAYRESULT_009: 'Payment Details',
  PAYRESULT_010: 'Back to Home',
  PAYRESULT_011: 'View Benefit',
  ORDERLIST_001: 'My Insurance Order',
  ORDERLIST_002: 'No more content',
  ORDERLIST_003: 'Order No.',
  ORDERLIST_004: 'Paid Amount',
  ORDERLIST_005: 'Pay',
  ORDERDETAIL_000: '{date} left to complete payment.',
  ORDERDETAIL_001: 'Order Details',
  ORDERDETAIL_002:
    'The platform is only for information collection and order display. The actual underwriting status is subject to the confirmation of the insurer.',
  ORDERDETAIL_003: 'Order Status',
  ORDERDETAIL_004:
    'After the insurance company completes the underwriting process, it can be insured.',
  ORDERDETAIL_005: 'Amount',
  ORDERDETAIL_006: 'ID Number',
  ORDERDETAIL_007: 'Order No.',
  ORDERDETAIL_008: 'Order Time',
  ORDERDETAIL_009: 'Payment Method',
  ORDERDETAIL_010: 'Payment Details',
  ORDERDETAIL_011: 'Cancel the order',
  ORDERDETAIL_012: 'Coverage Details',
  ORDERDETAIL_013: 'Details',
  ORDERDETAIL_014: 'Are you sure to cancel the order?',
  ORDERDETAIL_015: 'Please be aware of that all your options under this order will be cancelled.',
  ORDERDETAIL_016: 'Yes',
  ORDERDETAIL_018: 'Cancelled',
  ORDERDETAIL_019: 'Multiple payment methods',
  BALANCE_001: 'Remaing Amount',
  BALANCE_002: 'Instructions',
  BALANCE_003: 'Total granted amount',
  BALANCE_004: 'Check the expiration date of credits',
  BALANCE_005: 'Credit Detail',
  BALANCE_006: 'Details',
  BALANCE_007: 'No more content',
  BALANCE_008: 'Declaration',
  BALANCE_009: 'There is no credit records',
  BALANCE_010: 'Confirm',
  BALANCE_011: 'It will expire in 30 days',
  BALANCE_012: 'Details',
  BALANCE_013: 'All',
  BALANCE_014: 'Granted',
  BALANCE_015: 'Used',
  BALANCE_016: 'Credits Details',
  BALANCE_017: 'The list of credits that will expire in six months.',
  BALANCE_018: 'will expire on {year} {date}',
  BALANCE_019: 'You have no expiring point in the past 6 months.',
  POLICY_001: 'Benefits Policy',
  OVERVIEW_001: 'Start choosing your benefits plan',
  OVERVIEW_002: 'My Selections',
  OVERVIEW_003: 'Reselect',
  OVERVIEW_004: 'Back to Home',
  OVERVIEW_005: 'Modify the selection',
  OVERVIEW_006: 'Employee',
  OVERVIEW_007: 'Spouse',
  OVERVIEW_008: 'Chidren',
  OVERVIEW_009: 'Parents',
  OVERVIEW_010: 'Parents-in-Law',
  OVERVIEW_011: 'Details of insurance liability',
  OVERVIEW_012: 'Total',
  OVERVIEW_013: 'View points details',
  OVERVIEW_014: 'Details',
  OVERVIEW_015: 'Default Plan',
  INSURANCE_001: 'Available for purchasing insurance plans for you and your family members.',
  PRIVACY_001: 'Enter',
  PRIVACY_002: 'I have read and agree by all of the above',
  EXTRA_001: 'Reminder',
  EXTRA_002: 'Are you sure you want to remove [{prefix}]?',
  EXTRA_003: 'Confirm',
  EXTRA_004: 'Back',
  EXTRA_005: 'Mobile Phone',
  EXTRA_006: 'Details',
  EXTRA_007: '福利积分余额',
  EXTRA_008: '开启商保福利计划',
  EXTRA_016: '温馨提示：',
  EXTRA_017: '您的自选保障将进入待承保状态，预计将于开窗器结束后10个工作日内完成承保。',
  EXTRA_018: '保障生效日仍为自选当季度的第一天。',
  EXTRA_019: '自选计划',
  EXTRA_022: 'Back',
  EXTRA_030: '',
  EXTRA_031: '',
  EXTRA_032: '',
  EXTRA_045: '',
  EXTRA_046: '',
  CABIN_001: '',
  CABIN_002: '',
  CABIN_003: '',
  CABIN_004: '',
  CABIN_005: '',
  CABIN_006: '',
  CABIN_007: '',
  CABIN_008: '',

  ...elmentEnLocale,
};
